import React from 'react';

import { Box, Card, Typography } from '@material-ui/core';
import {
  AboutSectionContainer,
  ExternalLink,
} from '@product-site-frontend/shared';

import akraUpdate from './images/akra-update.png';
import afkSistemaLogo from './images/logo-afk-sistema.png';

export default function SectionCompanyInfo() {
  const info = [
    {
      label: 'Полное фирменное наименование',
      value: 'Общество с ограниченной ответственностью «АРЕНЗА-ПРО»',
    },
    { label: 'Сокращенное фирменное наименование', value: 'ООО «АРЕНЗА-ПРО»' },
    { label: 'ИНН', value: '7703413614' },
    { label: 'ОГРН', value: '1167746687316' },
    { label: 'Дата регистрации', value: '21.07.2016' },
    {
      label: 'Юридический адрес',
      value: '123112, г. Москва, ВН.ТЕР.Г. Муниципальный округ Пресненский, наб Пресненская, д. 6, стр. 2, этаж 5, помещ. 25Б',
    },
  ];

  const styles = {
    container: {
      display: { sm: 'block', lg: 'flex' },
      justifyContent: 'space-between',
    },
    infoBox: {
      width: '100%',
      py: 4,
      pl: 0,
      pr: { sm: 0, lg: 4 },
    },
    info: {
      label: { color: 'text.secondary', fontSize: 20, lineHeight: 1.9 },
      value: { fontSize: 22, fontWeight: 700, mt: 1 },
    },
    cardsBox: {
      py: 4,
      pl: { sm: 0, lg: 4 },
      pr: 0,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      px: 3,
      py: 8,
      width: { sm: '100%', lg: 344 },
      height: 277,
      boxShadow: '0 1px 50px 1px rgba(208, 208, 208, 0.5)',
      transform: 'translateZ(0)',

      '&:hover': {
        boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
      },
    },
    cardImage: {
      height: 70,
      display: 'flex',
      alignItems: 'center',
    },
    cardLabel: {
      fontSize: 20,
      fontWeight: 900,
      textAlign: 'center',
      mt: 5,
    },
    cardMarker: {
      fontSize: 20,
      fontWeight: 600,
      textAlign: 'center',
      mt: 5,
      background: '#FF7810',
      borderRadius: '4px',
      color: '#FFFFFF',
      padding: '0 4px',

      '& span': {
        fontWeight: 300,
      }
    },
    cardDate: {
      fontSize: 20,
      fontWeight: 500,
      textAlign: 'center',
      mt: 2,
    },
  };

  return (
    <AboutSectionContainer title="Общая информация о компании">
      <Box sx={styles.container}>
        <Box sx={styles.infoBox}>
          {info.map(({ label, value }, idx) => (
            <Box key={idx} sx={{ mb: idx !== info.length - 1 ? 4 : 0 }}>
              <Typography gutterBottom sx={styles.info.label} variant="caption">
                {label}
              </Typography>
              <Typography sx={styles.info.value}>
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box sx={styles.cardsBox}>
          <ExternalLink display="flex" href="https://sistema.ru/">
            <Card sx={{ ...styles.card, mb: 6 }}>
              <Box sx={styles.cardImage}>
                <img alt={'АФК "Система"'} src={afkSistemaLogo} width="300px" />
              </Box>
              <Typography sx={styles.cardLabel}>
                Акционеры С 2020 года
              </Typography>
            </Card>
          </ExternalLink>

          <ExternalLink
            display="flex"
            href="https://www.acra-ratings.ru/press-releases/4818/"
          >
            <Card sx={{ ...styles.card, padding:0,  mb: 6 }}>
                <img
                  alt={'Аналитическое кредитное рейтинговое агентство "AKRA"'}
                  src={akraUpdate}
                  width="300px"
                />
            </Card>
          </ExternalLink>
        </Box>
      </Box>
    </AboutSectionContainer>
  );
}