import React, { useCallback, useState } from "react";

import { Box, Typography, Link, Chip, Stack } from "@material-ui/core";
import { AboutSectionContainer } from '@product-site-frontend/shared';
import currency from "currency.js";

import P01 from "./images/bonds/001P-01.png";
import P02 from "./images/bonds/001P-02.png";
import P03 from "./images/bonds/001P-03.png";
import P04 from "./images/bonds/001P-04.png";
import BO01 from "./images/bonds/BO-01.png";

export default function SectionBonds() {
  const styles = {
    wrapperContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',

      flexWrap: {
        xs: 'wrap',
        sm: 'wrap',
        md: 'wrap',
        lg: 'nowrap',
      }
    },
    container: {
      display: { sm: 'block', lg: 'flex' },
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: '40px',
    },
    infoBox: {
      width: '100%',
      display: {
        xs: 'block',
        sm: 'flex',
        md: 'flex',
        lg: 'flex',
      }
    },
    info: {
      label: { color: 'text.secondary', fontSize: 20, marginBottom: 0 },
      value: { fontSize: 22, fontWeight: 700, display: 'inline'},
    },
    chip: {
      background: '#FFFFFF',
      fontSize: '20px',
      fontWeight: 500,
      color: '#6E7884',
      border: '1px solid #E7E8E9',
      transition: 'all 300ms',

      '&.active': {
        background: '#ee8035',
        color: '#FFFFFF',
        border: '1px solid #ee8035',
      },
      '&:hover': {
        background: '#f09249',
        color: '#FFFFFF',
        border: '1px solid #f09249',
      },
    }
  };

  const formatMoney = useCallback((value) => {
    return currency(value, { precision: 0 }).format({
      separator: ' ',
      // symbol: '¤',
      symbol: 'руб.',
      pattern: '# !',
    });
  }, []);

  const  [activeChip, setActiveChip] = useState(0);

  const onSetActiveChip = key => {
    setActiveChip(key);
  }

  const [chipData] = useState([
    {
      key: 0,
      label: 'БО-01',
      placementDate: '27.12.2021',
      img: BO01,
      amount: '300 000 000',
      percent: '14,5',
      isin: 'RU000A104C78',
      link: 'https://www.moex.com/ru/issue.aspx?board=TQIR&code=RU000A104C78&'
    },
    {
      key: 1,
      label: '001Р-01',
      placementDate: '09.02.2023',
      img: P01,
      amount: '300 000 000',
      percent: '14,5',
      isin: 'RU000A105TR7',
      link: 'https://www.moex.com/ru/issue.aspx?board=TQIR&code=RU000A105TR7&utm_source=www.moex.com&utm_term=%D0%B0%D1%80%D0%B5%D0%BD%D0%B7%D0%B0'
    },
    {
      key: 2,
      label: '001Р-02',
      placementDate: '04.07.2023',
      img: P02,
      amount: '400 000 000',
      percent: '13,0',
      isin: 'RU000A106GC4',
      link: 'https://www.moex.com/ru/issue.aspx?board=TQIR&code=RU000A106GC4&utm_source=www.moex.com&utm_term=RU000A106GC4'
    },
    {
      key: 3,
      label: '001Р-03',
      placementDate: '19.10.2023',
      img: P03,
      amount: '300 000 000',
      percent: '15,5',
      isin: 'RU000A107217',
      link: 'https://www.moex.com/ru/issue.aspx?board=TQCB&code=RU000A107217&utm_source=www.moex.com&utm_term=%D0%B0%D1%80%D0%B5%D0%BD'
    },
    {
      key: 4,
      label: '001Р-04',
      placementDate: '16.02.2024',
      img: P04,
      amount: '400 000 000',
      percent: '16,5',
      isin: 'RU000A107ST1',
      link: 'https://www.moex.com/ru/issue.aspx?board=TQCB&code=RU000A107ST1&utm_source=www.moex.com&utm_term=RU000A107ST1'
    },
  ]);

  return (
    <AboutSectionContainer title="Выпуски облигаций">
      <Stack direction="row" flexWrap={"wrap"} gap={"8px"} sx={{ marginBottom: '25px' }}>
        {chipData.map(data => (
          <Chip
            className={data.key === activeChip ? 'active' : null}
            key={data.key}
            label={`Выпуск ${data.label}`}
            onClick={onSetActiveChip.bind(this, data.key)}
            sx={ styles.chip }
          />
        ))}
      </Stack>

      {chipData.map(data => {
        if (data.key !== activeChip) return false;

        return (
          <Box key={data.key} sx={ styles.wrapperContainer }>
            <Box sx={styles.container}>
              <Box sx={styles.infoBox}>
                <Typography gutterBottom sx={styles.info.label} variant="caption">Облигационный выпуск серии</Typography>
                &nbsp;
                <Typography sx={styles.info.value}>{data.label}</Typography>
              </Box>

              <Box sx={styles.infoBox}>
                <Typography gutterBottom sx={styles.info.label} variant="caption">Дата размещения выпуска</Typography>
                &nbsp;
                <Typography sx={styles.info.value}>{data.placementDate}</Typography>
              </Box>

              <Box sx={styles.infoBox}>
                <Typography sx={styles.info.value}>{formatMoney(data.amount)}</Typography>
              </Box>

              <Box sx={styles.infoBox}>
                <Typography gutterBottom sx={styles.info.label} variant="caption">купон</Typography>
                &nbsp;
                <Typography sx={styles.info.value}>{data.percent}%</Typography>
              </Box>

              <Box sx={styles.infoBox}>
                <Typography gutterBottom sx={styles.info.label} variant="caption">ISIN</Typography>
                &nbsp;
                <Typography sx={styles.info.value}>{data.isin}</Typography>
              </Box>

              <Box sx={styles.infoBox}>
                <Link
                  color="#ff6600"
                  href={data.link}
                  variant="body1"
                >
                  <Typography sx={{ ...styles.info.value, lineHeight: 1.6 }}>Ссылка на выпуск</Typography>
                </Link>
              </Box>
            </Box>

            <img alt={`Облигационный выпуск серии ${data.label}`} src={data.img} style={{ maxWidth: "642px", width: "100%", margin: "0 auto" }} />
          </Box>
        )
      })}
    </AboutSectionContainer>
  );
}