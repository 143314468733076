import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@material-ui/core';

import DocumentsListItem from './DocumentsListItem';
import DocumentsListSkeleton from './DocumentsListSkeleton';

DocumentsList.propTypes = {
  documents: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
}

export default function DocumentsList({ documents, isLoading }) {
  if (isLoading) {
    return <DocumentsListSkeleton />;
  }

  if (!isLoading && !documents) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {documents.map((doc) => <DocumentsListItem doc={doc} key={doc.id} />)}
    </Box>
  );
}