import React from 'react';

import { Box, Card, Typography } from '@material-ui/core';
import {
  AboutSectionContainer,
  ExternalLink,
} from '@product-site-frontend/shared';

import rbkProLogo from './images/logo-rbk-pro.png';
import rusBaseLogo from './images/logo-rusbase.png';
import vcRuLogo from './images/logo-vcru.png';

export default function SectionMassMedia() {
  const styles = {
    cardsBox: {
      display: 'flex',
      flexDirection: { xs: 'column', lg: 'row' },
      justifyContent: 'space-between',
      py: 4,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      px: 3,
      py: 6,
      width: { xs: '100%', lg: 344 },
      mb: { xs: 6, lg: 0 },
      height: 277,
      boxShadow: '0 1px 50px 1px rgba(208, 208, 208, 0.5)',
      transform: 'translateZ(0)',

      '&:hover': {
        boxShadow: '0 16px 40px -12.125px rgba(0,0,0,0.3)',
      },
    },
    cardImage: {
      height: 70,
      display: 'flex',
      alignItems: 'center',
    },
    cardLabel: {
      fontSize: 20,
      fontWeight: 900,
      height: 90,
      textAlign: 'center',
      mt: 5,
    },
  };

  return (
    <AboutSectionContainer title="Пресса">
      <Box sx={styles.cardsBox}>
        <ExternalLink display="flex" href="https://rb.ru/opinion/lizing-oborudovaniya/">
          <Card sx={{ ...styles.card }}>
            <Box sx={styles.cardImage}>
              <img alt="RUSBASE" src={rusBaseLogo} width="200px" />
            </Box>
            <Typography sx={styles.cardLabel}>
              Лизинг оборудования: что<br />
              это и кому подойдет
            </Typography>
          </Card>
        </ExternalLink>
        <ExternalLink
          display="flex"
          href="https://vc.ru/offline/284295-pochemu-startapu-vygodno-brat-oborudovanie-v-lizing"
        >
          <Card sx={styles.card}>
            <Box sx={styles.cardImage}>
              <img alt="vc.ru" src={vcRuLogo} width="200px" />
            </Box>
            <Typography sx={styles.cardLabel}>
              Почему стартапу выгодно брать оборудование в лизинг?
            </Typography>
          </Card>
        </ExternalLink>
        <ExternalLink
          display="flex"
          href="https://pro.rbc.ru/news/611539879a794700aeaf5238"
        >
          <Card sx={styles.card}>
            <Box sx={styles.cardImage}>
              <img alt="РБК PRO" src={rbkProLogo} width="275px" />
            </Box>
            <Typography sx={styles.cardLabel}>
              &quot;Кофейня под ключ&quot; и сделки онлайн: куда движется лизинг
              в России
            </Typography>
          </Card>
        </ExternalLink>
      </Box>
    </AboutSectionContainer>
  );
}