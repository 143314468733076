import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import times from 'lodash/times';

export default function DocumentsAccordionSkeleton() {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {times(3, (idx) => (
        <Skeleton
          animation="wave"
          height={48}
          key={idx}
          sx={{
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 3,
            px: 3,
          }}
          variant="rectangular"
          width="100%"
        >
          <Skeleton
            height="75%"
            sx={{ bgcolor: 'grey.350', visibility: 'visible' }}
            width={100}
          />
          <Skeleton
            height="75%"
            sx={{ bgcolor: 'grey.350', visibility: 'visible' }}
            width={25}
          />
        </Skeleton>
      ))}
    </Box>
  );
}