import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import DocumentsAccordionSkeleton from './DocumentsAccordionSkeleton';
import DocumentsList from './DocumentsList';

DocumentsAccordion.propTypes = {
  altText: PropTypes.string,
  category: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      subcategory: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
};

export default function DocumentsAccordion({ altText, category, documents, isLoading }) {
  const [categorizedDocs, setCategorizedDocs] = useState([]);

  const [expanded, setExpanded] = useState(false);
  const handleChange = useCallback((panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }, []);

  useEffect(() => {
    if (!!documents) {
      const docsMap = new Map();

      [...documents]
        .forEach((doc) => {
          const subcategory = String(doc.subcategory).trim();
          docsMap.set(subcategory, [...(docsMap.get(subcategory) || []), doc]);
        });

      let sortedDocsMap = [...docsMap.entries()];

      if (category === 'issue_documents') {
        sortedDocsMap = sortedDocsMap.sort(([doc1], [doc2]) => doc2.localeCompare(doc1, undefined, { numeric: true, sensitivity: "base" }));
      } else {
        sortedDocsMap = sortedDocsMap.sort(([doc1], [doc2]) => doc2 - doc1);
      }

      setCategorizedDocs(sortedDocsMap);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const renderTitle = title => {
    if (category === 'accounting_statements') {
      return title ? `${title} год` : altText
    }

    return title || altText
  }

  if (isLoading) {
    return <DocumentsAccordionSkeleton />;
  }

  if (!isLoading && !categorizedDocs) {
    return null;
  }

  return (
    <>
      {categorizedDocs.map(([title, values]) => (
        <Accordion
          expanded={expanded === `panel-${title}`}
          key={title}
          onChange={handleChange(`panel-${title}`)}
        >
          <AccordionSummary
            expandIcon={
              expanded === `panel-${title}`
                ? <RemoveIcon />
                : <AddIcon />
            }
            sx={{ px: 3 }}
          >
            <Typography sx={{ fontSize: 20, fontWeight: 700 }}>
              {renderTitle(title)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentsList documents={values} isLoading={false} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}