import React, { useRef } from 'react';

import { Box } from '@material-ui/core';
import { useLocation } from '@reach/router';

import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import config from '../../config/website';
import logo from '../../static/images/logo.png';
import metaImage from '../../static/images/metaImage.jpg';
import {
  SectionAboutCompany,
  SectionCompanyInfo,
  SectionContacts,
  SectionFacts,
  SectionInfoDisclosure,
  SectionHaveQuestion,
  SectionMassMedia,
  SectionBonds,
} from '../components/AboutSections';
import PageBox from '../components/PageBox';
import SEO from '../components/SEO';

export default function AboutPage() {
  const investorsRef = useRef(null);
  const { hash } = useLocation();

  return (
    <PageBox scrollToNode={hash === '#investors' && investorsRef}>
      <SEO
        microData={{
          '@context': 'http://schema.org',
          '@type': 'Organization',
          image: `${config.siteUrl}${metaImage}`,
          url: config.siteUrl,
          logo: `${config.siteUrl}${logo}`,
          name: 'ООО «АРЕНЗА-ПРО»',
          description: 'Аренза – лизинговая компания для малого бизнеса, юридических лиц и ИП ✅ Взять оборудование в лизинг онлайн из любой точки России ✅ Выгодные условия, минимальные проценты',
          email: 'info@arenza.ru',
          telephone: '+7-800-775-7685',
          address: {
            '@type': 'PostalAdress',
            streetAddress: 'Пресненская набережная, д. 6с2',
            addressLocality: 'Москва',
            addressCountry: 'RU',
            postalCode: '123112'
          }
        }}
        pageData={{ title: 'О компании' }}
      />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            text: 'О нас'
          }
        ]}
        sx={{ breadcrumbsLine: { borderBottom: '1px solid #ebebeb' } }}
      />
      <SectionAboutCompany />
      <SectionFacts />
      <SectionCompanyInfo />
      <SectionBonds />
      <Box ref={investorsRef} sx={{ position: 'relative', top: -100 }} />
      <SectionInfoDisclosure />
      <SectionHaveQuestion />
      <SectionMassMedia />
      <SectionContacts />
    </PageBox>
  );
}
