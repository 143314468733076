import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import times from 'lodash/times';

export default function DocumentsListSkeleton() {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {times(6, (idx) => (
        <Box
          key={idx}
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: { xs: '100%', lg: '50%' },
            px: { xs: 1, lg: 3 },
            mb: 7,
          }}
        >
          <Skeleton
            height={48}
            sx={{ borderRadius: '10px', flex: 'none', mr: { xs: 2, lg: 4 } }}
            variant="rectangular"
            width={48}
          />
          <Skeleton height={24} variant="rectangular" width="100%" />
        </Box>
      ))}
    </Box>
  );
}