import React from 'react';

import { Box, Card, Typography } from '@material-ui/core';
import { AboutSectionContainer } from '@product-site-frontend/shared';

import telegaLogo from './images/logo-telegram.png';
import vkLogo from './images/logo-vk.png';

export default function SectionContacts() {
  const styles = {
    container: {
      display: { sm: 'block', lg: 'flex' },
      justifyContent: 'space-between',
    },
    contactsBox: {
      width: '100%',
      py: 4,
      pl: 0,
      pr: { sm: 0, lg: 4 },
    },
    socialsBox: {
      py: 4,
      pl: { sm: 0, lg: 4 },
      pr: 0,
    },
    card: {
      boxShadow: '0 1px 50px 1px rgba(208, 208, 208, 0.5)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      px: 9,
      py: { xs: 4, lg: 3 },
      width: { sm: '100%', lg: 480 },
    },
    socialsList: {
      display: 'flex',
      justifyContent: 'space-around',
      mt: { xs: 5, lg: 3 },
      width: '100%',
    },
    contactText: {
      fontSize: 22,
      fontWeight: 700,
    },
  };

  return (
    <AboutSectionContainer title="Контакты">
      <Box sx={styles.container}>
        <Box sx={styles.contactsBox}>
          <Typography
            gutterBottom
            sx={{ color: 'text.secondary', fontSize: 20, lineHeight: 1.9 }}
            variant="caption"
          >
            По любым вопросам
          </Typography>
          <Typography sx={{ ...styles.contactText, mt: 2 }}>
            <a href="tel:74951254344" rel="nofollow">+7 495 125 4344</a>
          </Typography>
          <Typography sx={{ ...styles.contactText, mt: 2 }}>
            <a href="tel:88007757685" rel="nofollow">8 (800) 775 7685{' '}</a>
            <Typography sx={{ fontSize: 20 }} variant="caption">
              (Бесплатно по России)
            </Typography>
          </Typography>
          <Typography sx={{ ...styles.contactText, mt: 2 }}>
            <a href="mailto:info@arenza.ru" rel="nofollow">info@arenza.ru</a>
          </Typography>
        </Box>
        <Box sx={styles.socialsBox}>
          <Card sx={styles.card}>
            <Typography sx={{ fontSize: 24, fontWeight: 700, mt: 1 }}>
              Следите за нами в социальных сетях
            </Typography>
            <Box sx={styles.socialsList}>
              <Typography
                component="a"
                href="https://vk.com/arenza_ru"
                rel="nofollow"
                target="_blank"
                variant="text"
              >
                <img alt="VK" src={vkLogo} width="42px" />
              </Typography>

              <Typography
                component="a"
                href="https://t.me/arenza_ru"
                rel="nofollow"
                target="_blank"
                variant="text"
              >
                <img alt="Telegram" src={telegaLogo} width="42px" />
              </Typography>
            </Box>
          </Card>
        </Box>
      </Box>
    </AboutSectionContainer>
  );
}