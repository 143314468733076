const DocButton = {
  alignItems: 'flex-start',
  color: '#4a90e2',
  fontSize: { xs: 14, lg: 18 },
  fontWeight: 700,
  textAlign: 'left',

  '& span': {
    minHeight: 45,
    display: 'flex',
    alignItems: 'center',
  },
}

const DocItem = {
  width: { xs: '100%', lg: '50%' },
  px: { xs: 1, lg: 3 },
  mb: 5,
}

const DocIcon = {
  fontSize: { xs: 36, lg: 48 },
  mr: { xs: 2, lg: 4 },
}

export default {
  DocButton,
  DocItem,
  DocIcon,
}