const TabTitle = {
  fontSize: 26,
  fontWeight: 700,
  mb: { xs: 6, lg: 9 },
  lineHeight: { xs: 1.2, lg: 1.5 },
  textAlign: 'center',
}

const Tabs = {
  borderColor: 'divider',
  borderRight: 1,
  pr: { xs: 2, lg: 4 },
  flex: 'none',
  width: { xs: 200, lg: 305 },

  '& .MuiTabs-indicator': {
    display: 'none',
  }
}

const TabsAdaptive = {
  borderColor: 'divider',

  '& .MuiTabs-indicator': {
    display: 'none',
  }
}

const Tab = {
  fontSize: { xs: 14, lg: 16 },
  width: 'auto',
  maxWidth: { xs: 220, lg: 1 },
  fontWeight: 700,
  alignItems: 'start',
  textAlign: { xs: 'center', lg: 'left' },
  textTransform: 'none',

  '&.Mui-selected': {
    color: 'text.primary',
  },
}

export default { TabTitle, Tabs, TabsAdaptive, Tab }