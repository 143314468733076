import React from 'react';

import { Box, Typography, Link } from "@material-ui/core";
import { AboutSectionContainer } from "@product-site-frontend/shared";

export default function SectionHaveQuestion() {
  const styles = {
    wrapper: {
      textAlign: "center",
    },
    text: {
      fontSize: '20px'
    }
  }

  return (
    <AboutSectionContainer>
      <Box  sx={ styles.wrapper }>
        <Typography component={"span"} sx={ styles.text }>По всем интересующим вопросам вы можете обратиться к Дмитрию Будняеву,</Typography>
        {" "}
        <Typography component={"span"} sx={ styles.text }>Руководителю блока по работе с банками и инвесторами,</Typography>
        {" "}
        <Link href={`mailto:dmitriy.budnyaev@arenza.ru`} sx={ styles.text } underline="none">
          dmitriy.budnyaev@arenza.ru
        </Link>
      </Box>
    </AboutSectionContainer>
  );
}