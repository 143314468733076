import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { ExternalLink } from '@product-site-frontend/shared';

import Styled from './InfoDisclosureTabs.styled';

DocumentsListItem.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.string,
    subcategory: PropTypes.string,
    title: PropTypes.string,
  }),
}

export default function DocumentsListItem({ doc }) {
  if (!doc) {
    return null;
  }

  return (
    <Box sx={Styled.DocItem}>
      <Typography
        color="primary"
        component={ExternalLink}
        fontWeight="500"
        href={
          `${process.env.GATSBY_FRONTOFFICE_API}/about_page_documents/${doc.id}/download`
        }
        sx={Styled.DocButton}
      >
        <PictureAsPdfIcon
          fontSize="inherit"
          htmlColor="#7d7d7d"
          sx={Styled.DocIcon}
        />
        <span>{doc.title}</span>
      </Typography>
    </Box>
  );
}